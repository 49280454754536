// import ScrollService from './NativeScroll'
import ScrollService from './VirtualScroll'
import { queries } from '../../core/config'
import Component from '../../core/Component'

ScrollService.getScrollOffset = ({element, isOffsetToCenteElement}) => {
    let y = 0

    if (window.matchMedia(queries.mediumMax).matches) {
        const header = document.querySelector('.Header')
        if (header) {
            const headerRect = header.getBoundingClientRect()
            y = headerRect.top + headerRect.height + 30
        }
    }

    if (isOffsetToCenteElement) {
        return y
    }

    const sectionCardEl = element.parentElement?.closest('[data-component="SectionCard"]')
    if (sectionCardEl) {
        const sectionCard = Component.getFromElement(sectionCardEl)
        if (sectionCard) {
            y += sectionCard.state?.distanceCurrent ?? 0
        }
    }

    return y
}

export const disableScroll = () => ScrollService.disableScroll
export const enableScroll = () => ScrollService.enableScroll
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService