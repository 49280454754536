// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

import NajaService from './services/Naja'
NajaService.initialize()

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { android, iOS, desktopSAFARI, CMS } from './core/config'
import ScrollService from './services/Scroll'
if (android || iOS || CMS || (typeof FORCE_NATIVE_SCROLL !== 'undefined' && FORCE_NATIVE_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch((err) => {
            ScrollService.useNative()
            console.log(err)
        })
        .finally(() => setScrollPosition())
}


import { loadComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from "lazysizes"
lazySizes.cfg.lazyClass = "is-lazy"
lazySizes.cfg.loadingClass = "is-lazy-loading"
lazySizes.cfg.loadedClass = "is-lazy-loaded"
document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg")
    if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")"
    }

})

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}